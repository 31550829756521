.fb_dialog_content iframe,
.fb_iframe_widget span iframe,
.fb_dialog_content div,
.container,
/* .fb_reset {
  right: 0 !important;
  left: 12px !important;
} */
.css-mvmu1r .Mui-selected,
.MuiMonthPicker-root .Mui-selected {
  color: white !important;
}
.css-1dozdou .Mui-selected,
.MuiYearPicker-root .Mui-selected,
.MuiDayPicker-weekContainer .Mui-selected {
  color: white !important;
}
.css-18vddjs-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: white !important;
}
#f310b47b7c2a5e {
  display: none;
}
.numberList li::marker {
  font-size: 0.875rem;
}

.dotActive {
  width: 8px !important;
}

.css-erv78u-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  box-shadow: none !important;
}

.css-mvmu1r {
  margin: 0 !important;
}

/* .slick-slide div {
  margin: 0 5px !important;
} */
/* .slick-slide {
  background-color: #efebe5 !important;
} */

.facebook-login,
.facebook-login1,
.facebook-login2,
.facebook-login3 {
  display: none;
}

.slick-list {
  box-shadow: none !important;
}

/* .next-arrow-destination {
  margin-right: 10%;
} */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
}

.loading-spinner {
  width: 100px;
  height: 100px;
  border: 10px solid rgba(7, 57, 56, 0.2);
  border-left-color: rgba(7, 57, 56, 1); /* Change this to the desired color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
